import { render, staticRenderFns } from "./Permisos.vue?vue&type=template&id=6926ab5e&scoped=true"
import script from "./Permisos.vue?vue&type=script&lang=js"
export * from "./Permisos.vue?vue&type=script&lang=js"
import style0 from "../assets/css/views/permisos.css?vue&type=style&index=0&id=6926ab5e&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6926ab5e",
  null
  
)

export default component.exports